(<template>
  <main class="public-profile">
    <header class="public-profile__header">
      <router-link :to="$makeRoute({name: 'Landing'})"
                   class="public-profile__logo" />
      <nav v-show="showNav"
           class="public-profile__nav-cont">
        <a :href="externalLoginPageLink"
           class="sk-btn sk-btn--white public-profile__btn-link">{{ $gettext('Log in') }}</a>
        <router-link :to="$makeRoute({name: 'SignUp'})"
                     class="sk-btn sk-btn--default public-profile__btn-link">{{ $gettext('Join now') }}</router-link>
        <locale-select class="public-profile__locale-select" />
      </nav>
      <button :class="{'is-opened-mobile-nav': mobileNavState}"
              class="public-profile__burger-btn"
              @click="toggleMobileNav">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
    </header>
    <dirbook-page />
  </main>
</template>)

<script>
  import DirBook from '@/pages/BuyerPages/assignment_pages/b_BookDirectly';
  import constants from '@/modules/constants';

  export default {
    asyncData({store}) {
      let promisesArr = '';

      if (!store.state.selectsData.language || !store.state.selectsData.language.length) {
        promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'language')];
      }
      if (!store.state.selectsData.qualification || !store.state.selectsData.qualification.length) {
        promisesArr = [...promisesArr, store.dispatch('fetchSelectList', 'qualification')];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'dirbook-page': DirBook
    },
    data() {
      return {
        clientWidth: 0,
        mobileNavState: true,
        externalLoginPageLink: this.$getMarketplaceName() === 'salita'
          ? constants.EXTERNAL_LOGIN_PAGE
          : this.$router.resolve(this.$makeRoute({name: 'SignIn'})).href
      };
    },
    computed: {
      showNav() {
        return this.clientWidth > 767 || this.mobileNavState;
      },
      uId() {
        if (this.$store.state.EditProfileStore.profileData && this.$store.state.EditProfileStore.profileData.person) {
          return this.$store.state.EditProfileStore.profileData.person.uid;
        }
      }
    },
    methods: {
      recalculateWidth() {
        this.clientWidth = document.documentElement.clientWidth;
      },
      toggleMobileNav() {
        this.mobileNavState = !this.mobileNavState;
      }
    },
    mounted() {
      this.recalculateWidth();
      window.addEventListener('resize', this.recalculateWidth);
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.recalculateWidth);
    }
  };
</script>

<style src="@assets/css/public_profile.css" scoped></style>
