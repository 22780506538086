(<template>
  <div class="post-assignment">
    <div class="post-assignment__breadcrumbs-wrapper">
      <router-link :to="interId ? $makeRoute({name: 'BuyerUserProfile', params: {id: interId}}): ''"
                   class="post-assignment__breadcrumbs-link">{{ interpreterName }}</router-link>
      <span class="post-assignment__breadcrumbs-arrow"></span>
      <p class="post-assignment__breadcrumbs-link">{{ $gettext('New assignment') }}</p>
    </div>
    <div v-if="userCanCreateEditJobs && userCanCreateDirectJobs || isPublicRoute"
         class="post-assignment__wrapper">
      <booking-form ref="bookingFormComponent"
                    :form-progress="bookingFormProgress"
                    :sections-list="sectionsList"
                    :validate-func="validatePage"
                    :summary-obj="summaryObj"
                    :submit-button-text="submitButtonText"
                    :send-info-func="sendInfoFunc"
                    :success-handling-func="handleSuccess"
                    :error-handling-func="handleError">
        <button v-if="showDirectAward"
                slot="submitButtonWithAddition"
                class="sk-btn post-form__dir-award-btn"
                @click.prevent.stop="directAward">{{ directAwardButtonText }}
        </button>
      </booking-form>
    </div>
    <no-rights v-else />
  </div>
</template>)

<script>
  import {mapState, mapMutations, mapGetters} from 'vuex';
  import PostInterpretationPrototype from '@/prototypes/PostInterpretationPrototype';

  export default {
    asyncData({store, route}) {
      let promisesArr = '';

      const country = route.query.int_country;
      const {slug, interpreterid: userId} = route.query;
      const id = !country || !slug ? userId : '';
      const profileUserUid = store.state.EditProfileStore.profileData?.uid || store.state.EditProfileStore.profileData?.person?.uid;

      if (profileUserUid !== id) {
        promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/getInterpreterProfile', {id, country, slug})];
      }
      if (store.state.TTAuthStore.loggedIn) {
        if (!route.query.copy) {
          promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/preselectInterpretationStaticData')];
        }
        promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/fetchMainData')];
        // Filling the selectors for pricing templates
        if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.demanderTemplates).length === 0) {
          promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'demander')];
        }
        if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.supplierTemplates).length === 0) {
          promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'supplier')];
        }
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'booking-form': () => import('@/components/booking_components/BookingForm'),
      'no-rights': () => import('@/components/shared_components/NoRights')
    },
    extends: PostInterpretationPrototype,
    data() {
      return {
        interId: this.$route.query.interpreterid,
        interCountry: this.$route.query.int_country,
        interSlug: this.$route.query.slug,
        assignmentDateProgress: false
      };
    },
    computed: {
      ...mapGetters('UserInfoStore', [
        'userCanCreateDirectJobs',
        'defaultJobDuration',
        'userAssignmentTypeDuration'
      ]),
      ...mapGetters('PostingNotifierStore', [
        'showDirectAward'
      ]),
      ...mapState('PostingStore/DateTimeStore', {
        // assignment info
        startTime: ({startTime}) => startTime,
        finishTime: ({finishTime}) => finishTime
      }),
      ...mapState('TTInterBookingStore', [
        'availableDates',
        'availableTimes',
        'relatedAvailableTimes'
      ]),
      ...mapState('EditProfileStore', {
        profileData: ({profileData}) => profileData || {}
      }),
      assignmentDate() { return this.startTime ? this.$moment(this.startTime).format('YYYY-MM-DD') : ''; },
      assignmentStartTime() { return this.startTime ? this.$moment(this.startTime).format('HH:mm') : ''; },
      assignmentFinishTime() { return this.finishTime ? this.moment(this.finishTime).format('HH:mm') : ''; },
      person() { return this.profileData.person || ''; },
      isPublicRoute() { return this.$route.name === 'PublicDirbook'; },
      minJobDuration() {
        return this.minJobDurationPerType || this.defaultJobDuration;
      },
      minJobDurationPerType() {
        const defaultJobTypeParams = this.userAssignmentTypeDuration.find((type) => type.name == this.assignmentType);
        return defaultJobTypeParams ? defaultJobTypeParams.minDuration : null;
      },
      interpreterName() {
        return this.person ? `${this.person.firstName || ''} ${this.person.lastName || ''}` : '';
      },
      relatedAvailableTimesList() {
        return this.assignmentDate === this.$moment().format('YYYY-MM-DD')
          ? this.relatedAvailableTimes
          : this.availableTimes;
      },
      btnText() { return this.$gettext('Send invite'); },
      assignmentInformationSection() {
        return {
          title: this.$gettext('Assignment information'),
          lines: [{
            components: [{
              name: 'int-info-section'
            }]
          }, {
            components: [{
              name: 'lang-pairs-section'
            }]
          }, {
            components: [{
              name: 'direct-processing-section',
              condition: this.showDirectProcessingSection,
              classes: ['post-form__field']
            }]
          }, {
            components: [{
              name: 'availability-date-time-section',
              params: {progress: this.assignmentDateProgress},
              condition: !this.supportMode
            }, {
              name: 'date-time-section',
              condition: this.supportMode
            }]
          }, {
            components: [{
              name: 'subject-section'
            }]
          }, {
            components: [{
              name: 'assignment-type-section',
              params: {isDirectBookingPage: true}
            }]
          }, {
            components: [{
              name: 'participants-section',
              condition: this.showParticipantsSection
            }]
          }, {
            components: [{
              name: 'extra-info-inner-section',
              params: {
                showExtraInfoNote: true
              }
            }]
          }, {
            components: [{
              name: 'attachments-inner-section'
            }]
          }]
        };
      },
      submitButtonText() { return this.submitBtnText || this.$gettext('Book now'); },
      sectionsList() {
        return [
          this.personalInformationSection,
          this.assignmentInformationSection,
          this.invoiceInformationSection,
          this.adminInformationSection
        ];
      }
    },
    watch: {
      minJobDurationPerType(newVal, oldVal) {
        if (oldVal !== null) {
          this.fetchAvailableDates(() => {
            return this.fetchAvailableTimes();
          });
        }
      },
      assignmentDate() {
        this.fetchAvailableTimes();
      },
      profileData() {
        if (this.profileData.bookable !== undefined && !this.profileData.bookable) {
          this.$store.commit('GlobalStore/stopProgress');
          this.openSupplierIsNotAvailableForBookModal();
        }
      }
    },
    methods: {
      ...mapMutations('PostInterpretationStore', [
        'setInterpreterUid'
      ]),
      ...mapMutations('PostingStore/DateTimeStore', [
        'setAssignmentStartDate',
        'setAssignmentFinishDate',
        'setStartTime',
        'setFinishTime',
        'resetTime'
      ]),
      ...mapMutations('TTInterBookingStore', [
        'setAvailabilityWarning',
        'clearAvailabilityWarning',
        'removeInterBookingData'
      ]),
      setStartInfo() {
        this.setInterpreterUid(this.interId);
      },
      sendInfoFunc() {
        return this.$store.dispatch('PostInterpretationStore/createLogoutJob');
      },
      fetchAvailableDates(cb) {
        if (this.interId && !this.supportMode) {
          const params = {
            uid: this.interId,
            current_time: this.$moment().toDate().toString(),
            duration: this.minJobDuration
          };
          this.assignmentDateProgress = true;
          this.clearAvailabilityWarning();
          this.$store.dispatch('TTInterBookingStore/fetchAvailableDate', {params}).then(() => {
            if (this.interId && (this.$route.name === 'BuyerBookDirectly' || this.$route.name === 'PublicDirbook') && !this.supportMode) {
              if (this.availableDates && this.availableDates.length && this.interId) {
                if (!this.isInAvailableDates(this.assignmentDate)) {
                  this.setAssignmentStartDate(this.availableDates[0]);
                  this.setAssignmentFinishDate(this.availableDates[0]);
                } else if (cb) {
                  cb();
                }
              } else {
                this.catchError();
              }
            } else {
              this.catchError();
            }
          }).catch(() => {
            this.catchError();
          });
        }
      },
      fetchAvailableTimes() {
        if (this.interId && this.availableDates && this.isInAvailableDates(this.assignmentDate) && !this.supportMode) {
          const params = {
            uid: this.interId,
            current_time: this.$moment().toDate().toString(),
            duration: this.minJobDuration,
            date: this.assignmentDate
          };
          this.assignmentDateProgress = true;
          this.clearAvailabilityWarning();
          this.$store.dispatch('TTInterBookingStore/fetchAvailableTime', {params}).then(() => {
            if (this.interId && (this.$route.name === 'BuyerBookDirectly' || this.$route.name === 'PublicDirbook') && !this.supportMode) {
              if (this.relatedAvailableTimesList && this.relatedAvailableTimesList.length) {
                if (!this.isInAvailableTimes(this.assignmentStartTime)) {
                  this.setStartTime(this.relatedAvailableTimesList[0]);
                }
                this.assignmentDateProgress = false;
              } else {
                this.catchError();
                this.setAvailabilityWarning(this.$gettext('The interpreter is unavailable this date. Please pick the one of the offered dates.'));
              }
            } else {
              this.catchError();
            }
          }).catch(() => {
            this.catchError();
          });
        }
      },
      catchError() {
        this.resetTime();
        this.assignmentDateProgress = false;
      },
      isInAvailableDates(date) {
        return this.availableDates.find((item) => item == date);
      },
      isInAvailableTimes(time) {
        return this.relatedAvailableTimesList.find((item) => item == time);
      },
      directAward() {
        this.$store.commit('PostInterpretationStore/setDirectAward', true);
        this.$refs.bookingFormComponent.submit();
      },
      getCopyJob() {
        let promisesArr = '';
        promisesArr = [...promisesArr, this.$store.dispatch('PostInterpretationStore/getCopyJob', {copyJobId: this.$route.query.copy})];
        Promise.all(promisesArr).then(() => {
          this.postAssignmentProgress = false;
        }).catch(() => {
          this.postAssignmentProgress = false;
        });
      }
    },
    mounted() {
      if (this.$route.query.copy) {
        this.getCopyJob();
      }
      this.setStartInfo();
      this.fetchAvailableDates(() => {
        return this.fetchAvailableTimes();
      });
    },
    beforeRouteLeave(to, from, next) {
      this.removeInterBookingData();
      this.$store.dispatch('PostInterpretationStore/clearStore');
      this.$store.commit('EditProfileStore/removeUserProfile');
      this.$store.commit('EnterpriseStore/removeAddresses');
      next();
    }
  };
</script>

<style scoped>
  .post-assignment__breadcrumbs-wrapper {
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  .post-assignment__breadcrumbs-arrow {
    display: inline-block;
    align-self: stretch;
    width: 10px;
    margin: 0 10px;
    background-image: url(~@assets/imgs/arrows/big_arrow_to_right_trans.svg);
    background-position: 50% 50%;
    background-size: auto 100%;
    background-repeat: no-repeat;
  }

  @media (max-width: 767px) {
    .post-assignment__breadcrumbs-wrapper {
      display: none;
    }
  }
</style>
