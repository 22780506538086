// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/arrows/big_arrow_to_right_trans.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.post-assignment__breadcrumbs-wrapper[data-v-20bea07f] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  font-size: 12px;\n}\n.post-assignment__breadcrumbs-arrow[data-v-20bea07f] {\n  display: inline-block;\n  -ms-flex-item-align: stretch;\n      align-self: stretch;\n  width: 10px;\n  margin: 0 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: auto 100%;\n  background-repeat: no-repeat;\n}\n@media (max-width: 767px) {\n.post-assignment__breadcrumbs-wrapper[data-v-20bea07f] {\n    display: none;\n}\n}\n", ""]);
// Exports
module.exports = exports;
